import React from 'react';

import css from './Accordeon.module.css';
import uniqueId from 'lodash/uniqueId';

const Accordeon = ({ radioName, dataArr, defaultChecked = -1 }) => {
  return (
    <div className={css.faq}>
      {dataArr.map((elem, id) => {
        return (
          <div key={uniqueId("Accordeon")}class={css.elemContainer}>
            <input className={css.inputBox} defaultChecked={defaultChecked === id} type="radio" id={radioName + id} name={radioName} />

            <label class={css.label} for={radioName + id}>
              {elem.label}
            </label>

            <div class={css.content}>{elem.content}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Accordeon;
